import { useState } from 'react';
import logo from '../logo.png';
import { ISubmit } from '../models/submit';
import { UploadForm } from './UploadForm';
import { UploadResults } from './UploadResults';

export const UploadPage = () => {
    const [submission, setSubmission] = useState<ISubmit>();

    return (
        <div className="App">
            <header className="App-body">
                <img src={logo} className="App-logo" alt="logo" />
                <h1 className="title">Smart Home Device Gateway CSV Upload Tool</h1>
                <br></br>
                <br></br>
                <div className="instructions">
                    <strong>Instructions:</strong>
                    <br></br>
                    <br></br>
                    CSV file must follow this format (with no empty or extra blank lines):
                    <br></br>
                    <pre>
                        <code className="code-block">
                            <p>"external_id","property_id","active"</p>
                            <p>"4315066","5420",1</p>
                            <p>"4315067","5426",1</p>
                            <p>"4315068","27450",1</p>
                            <p>"4315069","5474",1</p>
                            <p>"4315070","5475",1</p>
                        </code>
                    </pre>
                    <br></br>
                    "external_id" is the device id (DoorName in Kaba, CustomerID in PointCentral, unitName in
                    VirtualKEY, etc.)
                    <br></br>
                    "property_id" is the Vacasa UnitID<br></br>
                    "active" must have a value of 1 or 0 (true or false)
                </div>
                {submission != null ? ( //! something about this if statement causes submission to happen twice
                    <UploadResults submission={submission}></UploadResults>
                ) : (
                    <UploadForm submit={setSubmission}></UploadForm>
                )}
            </header>
        </div>
    );
};
