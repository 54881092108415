export const handleError = (e: any, setError: any) => {
    let message: string;
    if (e instanceof Error) {
        message = e.message;
    } else {
        message = 'An unknown error occurred.';
    }
    setError(message);
};

export const getTimestamp = () => {
    return new Date().toISOString().split('.')[0].replaceAll('-', '').replaceAll(':', '');
};
