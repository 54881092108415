/**
 * Validates an env variable is present
 * @param variable environment var name
 */
function validateAndGetEnvVar(variable: any): string {
    const value = process.env[variable];
    if (value) return value;
    const message = `Variable ${variable} is undefined or empty`;

    // Do not throw an error when unit testing
    if (process.env.NODE_ENV !== 'test') throw new Error(message);
    else return variable;
}

export const IDP_SCOPES = 'smart-home:write smart-home:read units:read units:write';
export const IDP_GATEWAY = process.env['REACT_APP_IDP_GATEWAY']; // empty on stage/prod
export const IDP_AUDIENCE = validateAndGetEnvVar('REACT_APP_IDP_AUDIENCE');
export const IDP_REDIRECT_URI = validateAndGetEnvVar('REACT_APP_IDP_REDIRECT_URI');
export const IDP_CLIENT_ID = validateAndGetEnvVar('REACT_APP_IDP_CLIENT_ID');
export const SMART_HOME_API = validateAndGetEnvVar('REACT_APP_SMARTHOME_API');
export const CONNECT_API = validateAndGetEnvVar('REACT_APP_CONNECT_API');
export const ENVIRONMENT = validateAndGetEnvVar('REACT_APP_CUSTOM_NODE_ENV_SHORT');
