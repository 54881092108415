export const SelectOptions = ({ data, selectGroup }: any) => {
    return data !== undefined ? (
        data.map(function (element: any) {
            return (
                <option value={element.id} key={selectGroup + element.id}>
                    {selectGroup === 'Vendor' ? element.attributes.display_name : element.attributes.name}
                </option>
            );
        })
    ) : (
        <option>Loading...</option>
    );
};
