import { ImplicitFlow } from '@vacasa/react-components-lib';
import { ENVIRONMENT, IDP_AUDIENCE, IDP_CLIENT_ID, IDP_REDIRECT_URI, IDP_SCOPES } from './core/constants';
import { UploadPage } from './components/UploadPage';

import './App.css';

function App() {
    return (
        <ImplicitFlow
            directoryHint="onelogin"
            clientId={IDP_CLIENT_ID}
            redirectUrl={IDP_REDIRECT_URI}
            scopes={IDP_SCOPES}
            audience={IDP_AUDIENCE}
            tokenRefresh={true}
            followRedirect={true}
            // on local authenticate on stage
            env={ENVIRONMENT === 'local' ? 'stage' : (ENVIRONMENT as any)}
        >
            <UploadPage></UploadPage>
        </ImplicitFlow>
    );
}

export default App;
