import { Output, ParsedRow, ResultRow } from '../../models/submit';
import { createDevice, createGateway, lockExists } from '../apis/smarthome';

export const uploadCsv = async (parsedData: ParsedRow[], setPercentage: any) => {
    let processed = 0;
    const output: Output = {
        total: parsedData.length,
        successRows: [],
        failRows: [],
    };
    for (const row of parsedData) {
        const result = await uploadRow(row);
        if (uploadSuccess(result.error_message)) {
            output.successRows.push(result);
        } else {
            output.failRows.push(result);
        }
        processed++;
        setPercentage(getPercentage(parsedData.length, processed));
    }
    return output;
};

const uploadRow = async (row: ParsedRow): Promise<ResultRow> => {
    const result: ResultRow = {
        external_id: row.external_id,
        property_id: row.property_id,
        active: row.active,
        error_message: '',
        prior_lock: await lockExists(row),
    };
    if (result.prior_lock) {
        result.error_message = 'Lock already exists';
        return result;
    }
    try {
        const gatewayId = await createGateway(row);
        await createDevice(row, gatewayId);
    } catch (e) {
        if (e instanceof Error) {
            result.error_message = e.message;
        } else {
            result.error_message = `An unknown error occured: ${e}`;
        }
    }
    if (result.error_message) result.error_message = `"${result.error_message.replaceAll(`"`, `'`)}"`;
    return result;
};

const getPercentage = (total: number, processed: number) => {
    return Math.round((processed / total) * 100) || 0;
};

const uploadSuccess = (error_message: string): boolean => {
    return error_message === '' || error_message.startsWith('Device upload succeeded');
};
