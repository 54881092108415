import { ParsedRow } from '../../models/submit';
import { handleRequest, smartHomeClient } from '../axios.client';
import { devicePayload, deviceStatusPaylod, gatewayPayload } from '../upload/build.request';

export const getDeviceRelation = async (endpoint: string, setter: any) => {
    const params = { 'filter[name]': 'Not Specified' };
    const response = await smartHomeClient.get(endpoint, { params });
    const id = response.data?.data[0].id;
    setter(id);
};

export const getVendors = async () => {
    const params = { 'filter[active]': 1, 'page[size]': 150 };
    const response = await smartHomeClient.get('vendors', { params });
    return response.data?.data;
};

export const getDeviceTypes = async () => {
    const response = await smartHomeClient.get('device_types');
    return response.data?.data;
};

export const deviceStatus = async (row: ParsedRow) => {
    const data = deviceStatusPaylod(row);
    const response = await smartHomeClient.post('/device_status', { data }).catch(handleRequest);
    const result = response.data?.data[0];
    if (
        result.type === 'success' ||
        (result.message.includes('Device not connected. Battery level unknown.') && result.vendor_response != null)
    ) {
        return;
    }
    throw Error(result.message);
};

export const createGateway = async (row: ParsedRow) => {
    const data = gatewayPayload(row);
    const response = await smartHomeClient.post('/device_gateways', { data }).catch(handleRequest);
    return response.data?.data.id;
};

export const createDevice = async (row: ParsedRow, gatewayId: any) => {
    const data = devicePayload(row, gatewayId);
    await smartHomeClient.post('/devices', { data }).catch(handleRequest);
};

export const lockExists = async (row: ParsedRow): Promise<string> => {
    const params = { 'filter[external_id]': row.external_id, 'filter[vendor_id]': row.vendor, 'filter[active]': 1 };
    const response = await smartHomeClient.get('/device_gateways', { params }).catch(handleRequest);
    return response.data?.data.length > 0 ? response.data?.data[0].id.toString() : '';
};
