import { ISubmit, Output, ResultRow } from '../../models/submit';

const header = ['External ID', 'Property ID', 'Active', 'Device Type', 'Vendor', 'Message', 'Prior Lock'];

export const generateCsvFile = (output: Output, submission: ISubmit): string => {
    const rowToCsv = (row: ResultRow) => {
        return [
            row.external_id,
            row.property_id,
            row.active,
            submission.device_type,
            submission.vendor,
            row.error_message,
            row.prior_lock,
        ];
    };
    const csvMeta = 'data:text/csv;charset=utf-8,';
    const csvData = [header, ...output.successRows.map(rowToCsv), ...output.failRows.map(rowToCsv)];
    console.log('csv data:', csvData);
    return encodeURI(csvMeta + csvData.map((e) => e.join(',')).join('\n'));
};
