import React, { useState } from 'react';
import { getDeviceTypes } from '../services/apis/smarthome';
import { SelectOptions } from './SelectOptions';

export const DeviceTypeSelect = ({ deviceTypeChange }: any) => {
    const [deviceTypeList, setDeviceTypeList] = useState<any[]>();
    React.useEffect(() => {
        const getDeviceTypeList = async () => {
            const deviceTypeList = await getDeviceTypes();
            setDeviceTypeList(deviceTypeList);
        };
        getDeviceTypeList();
    }, []);

    return (
        <select
            name="deviceType"
            id="deviceType"
            className="form-control"
            required={true}
            onChange={deviceTypeChange}
            defaultValue="default"
        >
            <option value="default"></option>
            <SelectOptions data={deviceTypeList} selectGroup={'DeviceType'}></SelectOptions>
        </select>
    );
};
