import { RequestHeaders } from '../../models/request.headers';
import { DeviceGatewayBody, ParsedRow } from '../../models/submit';

export const getHeaders = (): RequestHeaders => {
    const token = localStorage.getItem('accessToken');
    return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };
};

export const deviceStatusPaylod = (row: ParsedRow) => {
    return {
        type: 'device_status',
        attributes: {
            vendor_id: row.vendor,
            device_id: row.external_id,
        },
    };
};

export const gatewayPayload = (row: ParsedRow): DeviceGatewayBody => {
    return {
        type: 'device_gateways',
        attributes: {
            external_id: row.external_id,
            property_id: row.property_id,
            active: row.active,
            vendor: row.vendor,
            operator_id: 'device-gateway-csv-uploader',
        },
    };
};

export const devicePayload = (row: ParsedRow, deviceGateway: number) => {
    return {
        type: 'devices',
        attributes: {
            device_make: row.device_make,
            device_model: row.device_model,
            device_configuration: row.device_configuration,
            description: null,
            property_id: row.property_id,
            device_type: row.device_type,
            active: row.active,
            device_gateway: deviceGateway,
        },
    };
};
