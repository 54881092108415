import React, { FormEvent, useState } from 'react';
import { ISubmit } from '../models/submit';
import { getDeviceRelation } from '../services/apis/smarthome';
import { DeviceTypeSelect } from './DeviceTypeSelect';
import { VendorSelect } from './VendorSelect';

export const UploadForm = ({ submit }: any) => {
    const [file, setFile] = useState<File>();
    const [vendor, setVendor] = useState<string>();
    const [deviceType, setDeviceType] = useState<string>();
    const [deviceMake, setDeviceMake] = useState<string>();
    const [deviceModel, setDeviceModel] = useState<string>();
    const [deviceConfiguration, setDeviceConfiguration] = useState<string>();

    const changeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files == null) return;
        const eventFile: File = event.target.files[0];
        setFile(eventFile);
    };

    React.useEffect(() => {
        getDeviceRelation('device_makes', setDeviceMake);
        getDeviceRelation('device_models', setDeviceModel);
        getDeviceRelation('device_configurations', setDeviceConfiguration);
    }, []);

    class Submit implements ISubmit {
        file?: File = file;
        vendor: string = vendor || '';
        device_type: number = parseInt(deviceType || '');
        device_make: number = parseInt(deviceMake || '');
        device_model: number = parseInt(deviceModel || '');
        device_configuration: number = parseInt(deviceConfiguration || '');
        constructor(event: FormEvent<HTMLFormElement>) {
            event.preventDefault();
        }
    }

    return (
        <div className="Upload-form">
            <form
                method="POST"
                className="form-horizontal"
                onSubmit={(e) => {
                    submit(new Submit(e));
                }}
            >
                <div className="form-group">
                    <label htmlFor="name" className="col-md-3 col-sm-3 col-xs-12 control-label">
                        Device Type:{' '}
                    </label>
                    <div className="col-md-8">
                        <DeviceTypeSelect
                            deviceTypeChange={(e: any) => {
                                setDeviceType(e.target.value);
                            }}
                        ></DeviceTypeSelect>
                    </div>
                    <label htmlFor="name" className="col-md-3 col-sm-3 col-xs-12 control-label">
                        Vendor:{' '}
                    </label>
                    <div className="col-md-8">
                        <VendorSelect
                            vendorChange={(e: any) => {
                                setVendor(e.target.value);
                            }}
                        ></VendorSelect>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="name" className="col-md-3 col-sm-3 col-xs-12 control-label">
                        File:{' '}
                    </label>
                    <div className="col-md-8">
                        <input
                            type="file"
                            name="csv_file"
                            id="csv_file"
                            required={true}
                            onChange={changeFile}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-md-3 col-sm-3 col-xs-12 col-md-offset-3" style={{ marginBottom: '10px' }}>
                        <button className="btn btn-primary">
                            {' '}
                            <span className="glyphicon glyphicon-upload" style={{ marginRight: '5px' }}></span>
                            Upload{' '}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};
