import { parse } from 'papaparse';
import { ParsedRow, ISubmit } from '../../models/submit';

export const readCsv = async (submit: ISubmit) => {
    if (submit.file === undefined) throw Error('Empty file data uploaded.');
    const data = await submit.file?.text();
    const csvData = parse(data, {
        header: true,
    });
    return await processResults(csvData, submit);
};

const processResults = async (csvData: any, submit: ISubmit) => {
    if (csvData.errors.length > 0) {
        throw Error(`Unable to parse csv: ${csvData.errors}`);
    }
    verifyColumns(csvData.data[0]);
    const parsedData: ParsedRow[] = csvData.data.map((row: any) => parseRow(row, submit));
    return parsedData;
};

const parseRow = (row: any, submit: ISubmit): ParsedRow => {
    const parsedRow: ParsedRow = {
        device_type: submit.device_type,
        vendor: parseInt(submit.vendor),
        device_make: submit.device_make,
        device_model: submit.device_model,
        device_configuration: submit.device_configuration,
        ...getRowData(row),
    };
    return parsedRow;
};

const getRowData = (row: any) => {
    if (Array.isArray(row)) {
        // this shouldn't ever run since we parse with header
        return {
            external_id: row[0],
            property_id: row[1],
            active: parseInt(row[2]),
        };
    } else {
        return {
            external_id: row.external_id,
            property_id: row.property_id,
            active: parseInt(row.active),
        };
    }
};

const verifyColumns = (row: any) => {
    const requiredColumns = ['external_id', 'property_id', 'active'];
    for (const col of requiredColumns) {
        if (!row.hasOwnProperty(col)) {
            throw Error(`Unable to parse csv. Missing column "${col}"`);
        }
    }
};
