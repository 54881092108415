import axios from 'axios';
import { SMART_HOME_API, CONNECT_API } from '../core/constants';
import { getHeaders } from './upload/build.request';

export const smartHomeClient = axios.create({
    baseURL: SMART_HOME_API,
    headers: getHeaders(),
});

export const connectClient = axios.create({
    baseURL: CONNECT_API,
    headers: getHeaders(),
});

export const handleRequest = (error: any) => {
    const unknown = 'An unknown error occurred';
    if (error.hasOwnProperty('response')) {
        const url = error.request?.responseURL.split('/');
        const endpoint = url[url.length - 1];
        const errorMessage =
            'errors' in error.response.data
                ? error.response.data.errors[0]?.detail
                : error.response.data.data[0]?.message; // accounts for different response format of device_status
        throw new Error(`/${endpoint} error: ${errorMessage}` || unknown);
    }
    if (error.hasOwnProperty('request')) {
        throw new Error(error.request);
    }
    throw error;
};
