import React from 'react';
import { useState } from 'react';
import { ISubmit, Output, ResultRow } from '../models/submit';
import { generateCsvFile } from '../services/output/generate.csv';
import { getTimestamp, handleError } from '../services/output/results';
import { readCsv } from '../services/upload/read.csv';
import { uploadCsv } from '../services/upload/upload';

export const UploadResults = ({ submission }: { submission: ISubmit }) => {
    const [output, setOutput] = useState<Output>({
        total: 0,
        successRows: [],
        failRows: [],
    });
    const [percentage, setPercentage] = useState<number>(0);
    const [csvFile, setCsvFile] = useState<string>();
    const [error, setError] = useState<string>('');
    React.useEffect(() => {
        const upload = async () => {
            try {
                const parsedData = await readCsv(submission);
                const results = await uploadCsv(parsedData, setPercentage);
                setOutput(results);
                setCsvFile(generateCsvFile(results, submission));
            } catch (e) {
                handleError(e, setError);
            }
        };
        upload();
    }, [submission]);

    return (
        <div className="Upload-status">
            {error !== '' ? (
                <p className="status">
                    <b>Upload failed:</b> {error}
                </p>
            ) : percentage < 1 ? (
                <div className="status">
                    <p>
                        <i className="form-spinner glyphicon glyphicon-refresh"></i> Processing...(
                        <span id="percentage">%{percentage}</span>)
                    </p>
                </div>
            ) : (
                <div id="results">
                    <table id="errors" className="dataTable">
                        <thead>
                            <tr>
                                <td>External ID</td>
                                <td>Property ID</td>
                                <td>Error Message</td>
                                <td>Prior Lock</td>
                            </tr>
                        </thead>
                        <tbody>
                            {output.failRows.map(function (row: ResultRow) {
                                return (
                                    <tr key={`f_${row.external_id}_${row.property_id}`}>
                                        <td>{row.external_id}</td>
                                        <td>{row.property_id}</td>
                                        <td>{row.error_message}</td>
                                        <td>{row.prior_lock}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <table id="download" className="dataTable">
                        <tbody>
                            <tr>
                                <td>
                                    <a
                                        id="download-button"
                                        className="btn btn-primary"
                                        download={`processed_gateways_${getTimestamp()}.csv`}
                                        href={csvFile}
                                    >
                                        Download processed data
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
