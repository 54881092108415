import React, { useState } from 'react';
import { getVendors } from '../services/apis/smarthome';
import { SelectOptions } from './SelectOptions';

export const VendorSelect = ({ vendorChange }: any) => {
    const [vendorList, setVendorList] = useState<any[]>();
    React.useEffect(() => {
        const getVendorList = async () => {
            const vendors = await getVendors();
            setVendorList(vendors);
        };
        getVendorList();
    }, []);

    return (
        <select
            name="vendor"
            id="vendor"
            className="form-control"
            required={true}
            onChange={vendorChange}
            defaultValue="default"
        >
            <option value="default"></option>
            <SelectOptions data={vendorList} selectGroup={'Vendor'}></SelectOptions>
        </select>
    );
};
